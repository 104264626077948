@import "src/_app/styles/mixins.scss";
.partnership {
  display: flex;
  flex-flow: column;
  color: var(--white);

  h2 {
    margin: 0;

    @include tTitle_1_main;

    @include respond(tablet) {
      font-size: 32px;
      line-height: 42px;
    }
  }
}

.longLang {
  word-break: break-all;
}
