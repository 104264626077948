@import "src/_app/styles/mixins.scss";
.container {
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  row-gap: 28px;

  @include respond(tablet) {
    padding-top: 32px;
  }

  .swiper {
    width: 100%;
    overflow: visible;
    align-items: center;
    position: relative;
    z-index: 10;

    & > div {
      cursor: grab;

      @media only screen and (width >=1900px) {
        cursor: default;
      }
    }
  }
}

.swiper__slide {
  width: 302px !important;
}

.buttons {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  width: fit-content;
  margin: 0 auto;
  z-index: 99;

  button {
    padding: 4px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow:
      0 4px 36px 0 rgb(0 0 0 / 29%),
      0 0 12px 0 rgb(37 33 41 / 4%);
    transition: background 0.3s ease;
    background: #1a1a1a;
    cursor: pointer;
    border: none;

    &:disabled {
      background: #0a0a0a;
      pointer-events: none;
    }

    &:hover,
    &:active {
      background: #2a2a2a;
    }

    svg {
      position: relative;
      width: 24px;
      height: 24px;

      @include rtl {
        transform: rotate(180deg);
      }
    }

    &:first-of-type {
      svg {
        transform: rotate(180deg);
        inset-inline-start: 0;

        @include rtl {
          transform: rotate(0deg);
        }
      }
    }
  }
}
