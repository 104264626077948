@import "src/_app/styles/mixins.scss";
.product {
  max-height: 200px;
  min-height: 180px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #272727;
  border-radius: 20px;
  background: #0a0a0a;
  align-items: center;
  background-repeat: no-repeat;
  background-position: bottom right;

  @include respond(tablet) {
    padding: 16px;
  }

  @include rtl {
    background-position: bottom left;
  }

  &__info {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    &__body {
      display: flex;
      flex-flow: column;
      gap: 8px;

      &__top {
        top: 0;
        left: 0;
        display: flex;
        gap: 4px;
        align-items: center;

        h4 {
          margin: 0;
          width: max-content;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;

          @include respond(tablet) {
            width: auto;
          }
        }

        &__type {
          height: 30px;
          padding: 7px 8px;
          display: flex;
          background: linear-gradient(
            180deg,
            rgb(82 82 82 / 24%) 0%,
            rgb(10 10 10 / 24%) 100%
          );
          border: 1px solid #7a7a7a;
          border-radius: 20px;
          align-items: center;

          @include tTag;
        }
      }
    }

    a {
      width: max-content;
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #828282;
    }
  }

  .tablet_top {
    h4 {
      @include tChatTime;
      word-break: break-word;
    }
  }
}

.tablet_product {
  min-height: 92px;
  padding: 16px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.mobile_icon_wrapper {
  display: flex;
  justify-content: center;
}

.learn_more_link {
  margin-top: auto;
}
