@import "src/_app/styles/mixins.scss";
.container {
  margin: 0 auto;
  max-width: 841px;
  width: 100%;
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 84px;
  color: var(--white);

  @include respond(laptopL) {
    gap: 32px;
  }

  &__info {
    margin: auto 0;
    display: flex;
    flex-flow: column;
    gap: 16px;

    h2 {
      margin: 0;

      @include tTitle_1_main;
    }

    h3 {
      margin: 0;

      @include tTitle_2;
    }

    &__links {
      margin-top: 20px;

      &__qr {
        display: flex;
        align-items: center;
        gap: 16px;

        @include respond(tablet) {
          display: none;
        }

        span {
          max-width: 243px;

          @include tCaption;
        }
      }

      &__stores {
        margin-top: 16px;
        display: flex;
        gap: 16px;
      }
    }
  }
}

.mobile_container {
  position: relative;
  height: auto;
  min-height: 620px;
  display: flex;
  flex-flow: column;
  color: var(--white);
  align-items: center;

  h2 {
    margin-bottom: 14px;
    text-align: center;

    @include Land_32;

    @include respond(mobileL) {
      text-align: start;
    }
  }

  &__image_container {
    height: 412px;
    width: 208px;
    overflow: hidden;
    mask: linear-gradient(to bottom, white 15%, transparent 100%);
  }

  &__info {
    position: absolute;
    top: 520px;
    display: flex;
    flex-flow: column;
    align-items: center;

    * {
      z-index: 2;
    }

    h3 {
      text-align: center;

      @include tSubtitle;
    }

    &__links {
      margin-top: 16px;
      display: flex;
      gap: 16px;
    }

    .downloadButtons {
      justify-content: center;
    }
  }
}
