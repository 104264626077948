@import "src/_app/styles/mixins.scss";
.main {
  background: var(--black);
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding: 40px 80px;

  @include respond(laptop) {
    padding: 40px 32px;
  }

  @include respond(tablet) {
    padding: 24px;
  }

  @include respond(tabletS) {
    padding: 24px 16px;
  }
}

.partners {
  overflow: hidden;
  background: #0f0f0f;
}

.access_block {
  position: relative;
  padding: 40px 0;
  background: url('/icons/main/circles-background-icon.svg') center center
    no-repeat;
  z-index: 1;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    background: linear-gradient(0deg, rgb(15 15 15 / 0%) 0%, #0f0f0f 90%);
    z-index: -1;
    content: '';
  }
}

.join_cryptomus_block {
  position: relative;
  margin: 0 auto;
  max-width: 1920px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/img/main/join_crypt_desktop_bg.png');
  background-size: cover;

  @include respond(tabletS) {
    background-image: url('/img/main/join_crypt_mobile_bg.png');
  }

  &::after {
    display: none;

    @media (width >= 1920px) {
      content: ' ';
      position: absolute;
      top: 0;
      left: -100px;
      width: calc(100% + 200px);
      height: 100%;
      display: block;
      background: linear-gradient(
        to right,
        rgb(0 0 0 / 30%) 0%,
        rgb(0 0 0 / 0%) 20%,
        rgb(0 0 0 / 0%) 80%,
        rgb(0 0 0 / 60%) 100%
      );
    }
  }

  .container {
    position: relative;
    padding-bottom: 0;
    display: block;
    z-index: 3;

    @include respond(mobileL) {
      padding: 40px 23px 0;
    }
  }
}

.sign_up__background {
  background-image: url('/img/main/sign-up-background.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #020202;

  @include respond(laptopC) {
    background-color: transparent;
    background-image: none;
  }

  @include respond(tablet) {
    overflow-x: hidden;
  }
}
