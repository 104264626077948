@import "src/_app/styles/mixins.scss";
.scroll_parent {
  --gap: 24px;

  display: flex;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    hsl(0deg 0% 0% / 0%),
    hsl(0deg 0% 0% / 100%) 20%,
    hsl(0deg 0% 0% / 100%) 80%,
    hsl(0deg 0% 0% / 0%)
  );

  @include respond(tabletS) {
    --gap: 12px;
  }
}

.scroll_element {
  display: flex;
  animation: slide 80s linear infinite;

  @include rtl {
    animation: rtlSlide 80s linear infinite;
  }

  a {
    margin-right: var(--gap);
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    /* stylelint-disable-next-line */
    transform: translateX(calc(-100% - var(--gap, 0px)));
  }
}

@keyframes rtlSlide {
  0% {
    transform: translateX(0);
  }

  100% {
    /* stylelint-disable-next-line */
    transform: translateX(calc(100% - var(--gap, 0px)));
  }
}
