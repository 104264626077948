@import "src/_app/styles/mixins.scss";
.products {
  display: flex;
  flex-flow: column;
  gap: 24px;
  color: var(--white);

  h1 {
    margin-bottom: 12px;

    @include tTitle_1_main;

    @include respond(tablet) {
      font-size: 32px;
      line-height: 42px;
    }
  }

  &__row {
    display: grid;
  }

  &__first_row {
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @media only screen and (width <= 1300px) {
      grid-template-columns: 1fr;
      gap: 24px;
    }
  }

  &__second_row {
    // TODO
    // grid-template-columns: 1fr 1fr 1fr;
    // gap: 25px;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  &__third_row {
    // TODO
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    // gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
  }

  &__fourth_row {
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
  }

  &__area {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 15px;

    @include respond(laptopC) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(4, 1fr);
    }

    @include respond(tablet) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
